<template>
  <div class="newsBody">
    <div class="coverBox">
      <img :src="require('../../assets/images/news/cover.png')" alt="">
      <div class="coverBody">
        <div class="title" :class="{weibei:lang}">
          {{$t('newTitle')}}
        </div>
        <div class="buttonBox">
          <div class="button" :class="{weibei:lang}" @click="goNewsPage">{{$t('INBAR_news')}}</div>
          <div class="button active" :class="{weibei:lang}">{{$t('BARC_News')}}</div>
        </div>
      </div>
    </div>
    <div class="listBox">
      <div class="item" v-for="(el,index) in newsData" :key="el._id.$id" @click="goDetailPage(el)">
        <div class="newsPhoto">
          <el-image
            style="width: 100%; height: 100%"
            :src="el.cover"
            :fit="'cover'"></el-image>
        </div>
        <div class="contentBox">
          <div class="contentTitle" v-if="el.title">
            {{el.title[lang?'zh':'en']}}
          </div>
          <div class="contentText" v-if="el.content" v-html="el.content[lang?'zh':'en']">
          </div>
        </div>
        <div class="dateBox">
          <div class="day">{{el.date}}</div>
          <div class="month">{{el.month}} {{lang?'月':'Month'}}</div>
          <div class="year">{{el.year}}</div>
        </div>
      </div>
    </div>
    <div class="paginationBox">
      <el-pagination
        :page-size="10"
        background
        layout="pager"
        :total="10">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      newsData:[]
    };
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === 'zh-CN';
    },
  },
  async mounted() {
    const result = await axios.get('https://stage.chinafuturelink.com/fu/api/news?meeting_id=627b6984b4eb1908351e8f77&page=1&limit=10&content_txt=1');
    if(result.status === 200) {
      this.newsData = result.data.map(item=>{
        item.year = new Date(item.publish_date*1000).getFullYear();
        item.month = new Date(item.publish_date*1000).getMonth()+1;
        item.date = new Date(item.publish_date*1000).getDate();
        return item;
      });
    }
  },
  methods: {
    goDetailPage(item) {
      window.open(`https://barc2022.chinafuturelink.com/#/meeting/news/detail?news_id=${item._id.$id}&meeting_id=${item.meeting_id}`)
    },
    goNewsPage() {
      window.open(
        this.$t('INBAR_new_url')
      );
    }
  }
}
</script>
<style lang="stylus">
@media screen and (min-width: 728px)
  .paginationBox
    .el-pagination
      .el-pager
        li
          width: 50px;
          height: 50px;
          border 1px solid #E6E6E6;
          background: #FFFFFF;
          margin 0;
          padding 0;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
          font-size: 16px;
          font-weight: 400;
          &:hover
            color: #8DC32B !important;
        .active
          background: #8DC32B !important;
          &:hover
            color: #FFFFFF !important;
      .btn-next
        width: 50px;
        height: 50px;
        border 1px solid #E6E6E6;
        background: #FFFFFF;

</style>
<style lang="stylus" scoped>
@media screen and (max-width: 728px)
  .newsBody
    width: 100%;
    overflow hidden;
    .coverBox
      width: 100%;
      height: auto;
      position relative;
      overflow hidden;
      img
        width: auto;
        height: 200px;
        position: absolute;
        top 0;
        right 0;
      .coverBody
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 240px;
        .title
          width: 150px;
          height: 60px;
          background: rgba(141, 198, 63, 0.9);
          font-size: 24px;
          color: #FFFFFF;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
        .buttonBox
          height: 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          .button
            width: 100px;
            height: 44px;
            cursor pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(141, 198, 63, 0.9);
            font-size: 16px;
            font-weight: 600;
            color #ffffff;
            &:nth-child(1)
              margin-right 14px;
          .active
            background: #405E3C;
    .listBox
      height auto;
      width: 100%;
      margin: 30px auto 20px auto;
      box-sizing: border-box;
      padding: 0 15px;
      .item
        width: 100%;
        padding 12px 0 15px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: space-between;
        cursor pointer;
        .newsPhoto
          width: 100%;
          height: 180px;
          display: flex;
          align-items: center;
          img
            width: 100%;
            height: auto;
        .contentBox
          width: 100%;
          height: auto;
          box-sizing: border-box;
          .contentTitle
            font-size: 16px;
            font-weight: 600;
            color #405E3C;
            text-align: left;
            margin-top: 10px;
            line-height: 24px;
          .contentText
            font-size: 14px;
            line-height: 21px;
            color #606266;
            text-align: left;
            margin-top: 10px;
            display: -webkit-box;//谷歌
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;//显示几行
            overflow: hidden;
        .dateBox
          width: 90px;
          height: 90px;
          background: #8DC63F;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          position: relative;
          margin-bottom: 10px;
          .day
            font-size: 40px;
            font-weight: 600;
            position: absolute;
            line-height: 56px;
            left 50%;
            transform: translate3d(-50%,0,0);
            top: 1px;
            font-family: 'PingFang SC';
          .month
            font-size: 16px;
            line-height: 22.4px;
            position: absolute;
            left 50%;
            transform: translate3d(-50%,0,0);
            top: 46px;
            font-family: 'PingFang SC';
            width 100%
          .year
            font-size: 16px;
            line-height: 22.4px;
            position: absolute;
            left 50%;
            transform: translate3d(-50%,0,0);
            top: 64px;
            font-family: 'PingFang SC';
    .paginationBox
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px 0 40px 0;

@media screen and (min-width: 728px)
  .newsBody
    width: 100%;
    overflow hidden;
    .coverBox
      width: 100%;
      height: auto;
      position relative;
      img
        width: 100%;
        height: auto;
      .coverBody
        position: absolute;
        width: 100%;
        height: 100%;
        top 0;
        left 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title
          width: 233px;
          height: 80px;
          background: rgba(141, 198, 63, 0.9);
          font-size: 42px;
          color: #FFFFFF;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
        .buttonBox
          height: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 23px;
          .button
            width: 130px;
            height: 54px;
            cursor pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(141, 198, 63, 0.9);
            font-size: 20px;
            font-weight: 600;
            color #ffffff;
            &:nth-child(1)
              margin-right 24px;
          .active
            background: #405E3C;
    .listBox
      height auto;
      max-width: 1400px;
      margin: 69px auto 50px auto;
      box-sizing: border-box;
      padding 0 60px;
      .item
        width: 100%;
        padding 12px 0 15px 0;
        box-sizing: border-box;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor pointer;
        .newsPhoto
          width: 288px;
          height: 180px;
          display: flex;
          align-items: center;
          img
            width: 100%;
            height: auto;
        .contentBox
          flex 1;
          height: 180px;
          box-sizing: border-box;
          padding 0 75px 0 50px;
          .contentTitle
            font-size: 20px;
            font-weight: 600;
            color #405E3C;
            text-align: left;
            margin-top: 23px;
            line-height: 30px;
            display: -webkit-box;//谷歌
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;//显示几行
            overflow: hidden;
          .contentText
            font-size: 16px;
            line-height: 24px;
            color #606266;
            text-align: left;
            margin-top: 25px;
            display: -webkit-box;//谷歌
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;//显示几行
            overflow: hidden;
        .dateBox
          width: 90px;
          height: 90px;
          background: #8DC63F;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #FFFFFF;
          position: relative;
          .day
            font-size: 40px;
            font-weight: 600;
            position: absolute;
            line-height: 56px;
            left 50%;
            transform: translate3d(-50%,0,0);
            top: 1px;
            font-family: 'PingFang SC';
          .month
            font-size: 16px;
            line-height: 22.4px;
            position: absolute;
            left 50%;
            transform: translate3d(-50%,0,0);
            top: 46px;
            font-family: 'PingFang SC';
            width 100%
          .year
            font-size: 16px;
            line-height: 22.4px;
            position: absolute;
            left 50%;
            transform: translate3d(-50%,0,0);
            top: 64px;
            font-family: 'PingFang SC';
    .paginationBox
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 116px;

</style>